import React, { useEffect } from "react";
import { ScientistBtn } from "./ScientistBtn";
import { Typography } from "@mui/material";
import HumantarianImg3 from "../../assets/images/HumantarianImg3.png";
import { EduCrousal } from "./EduCrousal";

import "aos/dist/aos.css";
import { convertImagePathToUrl } from "../../utils";

const formatModalDescription = (description) =>
  description?.replace(/(\r\n|\r|\n)/g, "<br>");

export const Educator = ({ educators, advocacy }) => {
  useEffect(() => {
    const handleScroll = () => {
      const elements = document.querySelectorAll(".aos-init");

      elements.forEach((el) => {
        const rect = el.getBoundingClientRect();
        const triggerPoint = window.innerHeight / 2;

        if (rect.top < triggerPoint) {
          el.style.transitionDuration = "0.2s";
          el.style.transitionTimingFunction = "cubic-bezier(0.4, 0, 1, 2)";
          el.classList.add("aos-animate");
        }
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if (educators?.length === 0 && advocacy?.length === 0) return;

  return (
    <div>
      <div
        id="The Educator"
        className="portfolioImg6"
        style={{ overflowX: "hidden", overflowY: "hidden" }}
      >
        <div
          className="flex justify-between"
          data-aos="fade-left"
          data-aos-delay="100"
        >
          <div className="justify-end hidden lg:flex" style={{ flex: 1 }}>
            <img
              src={HumantarianImg3}
              className="h-full ml-auto leftArrowStyle"
              alt=""
            />
          </div>
          <div
            className="flex flex-col items-center justify-center py-10"
            style={{ flex: 1.75, background: "#EBEEF3" }}
          >
            <div className="mx-20 lg:mx-0" style={{ maxWidth: "650px" }}>
              <Typography
                className="text-4xl font-bold mb-2"
                style={{
                  fontFamily: "Montserrat",
                }}
              >
                {educators?.section_heading}
              </Typography>
              {educators?.details?.map((detail, index) => (
                <>
                  {detail?.singleArray?.map((det, ind) => (
                    <>
                      {det?.key === "sub-heading" && (
                        <Typography
                          key={ind}
                          className="my-3 text-base"
                          style={{
                            fontWeight: 600,
                            fontFamily: "Montserrat",
                          }}
                        >
                          {det?.sub_heading}
                        </Typography>
                      )}

                      {det?.key === "sub-heading" && (
                        <Typography
                          key={"desc"}
                          className="mx-auto text-sm"
                          style={{
                            fontWeight: 500,
                            fontFamily: "Montserrat",
                          }}
                          dangerouslySetInnerHTML={{
                            __html: formatModalDescription(
                              det?.sub_heading_desc
                            ), // Correctly using dangerouslySetInnerHTML here
                          }}
                        />
                      )}

                      {det?.key === "btt" && (
                        <div className="flex justify-start">
                          <ScientistBtn Sbutton1={det?.childButtons} />
                        </div>
                      )}
                    </>
                  ))}
                </>
              ))}
            </div>
          </div>
          <div
            className="justify-end hidden lg:flex pr-24"
            style={{ flex: 1, background: "#EBEEF3" }}
          >
            {educators?.details?.map((detail, index) => (
              <React.Fragment key={index}>
                {detail?.assets?.map((asset, assetIndex) => (
                  <div
                    className="flex flex-col justify-center"
                    key={assetIndex}
                  >
                    {asset?.images?.map((img, imgIndex) => (
                      <img
                        key={imgIndex}
                        src={convertImagePathToUrl(img.image_url)}
                        className="w-full"
                        alt=""
                      />
                    ))}
                  </div>
                ))}
              </React.Fragment>
            ))}
          </div>
        </div>
        <div className="pt-20">
          <EduCrousal advocacy={advocacy} />
        </div>
      </div>
    </div>
  );
};
