import React, { useEffect } from "react";
import { Typography } from "@mui/material";
import WhiteArrowRight from "../../assets/images/WhiteArrowRight.png";
// import HumantarianImg2 from "../../assets/images/HumantarianImg2.png";
// import HumantarianImg3 from "../../assets/images/HumantarianImg3.png";
import { AuthorPolygon } from "./AuthorPolygon";
import "aos/dist/aos.css";
import { convertImagePathToUrl } from "../../utils";

export const Humantarian = ({ humantarian, birthday, author }) => {
  // console.log("Author: ", author);
  const formatModalDescription = (description) =>
    description?.replace(/(\r\n|\r|\n)/g, "<br>");

  useEffect(() => {
    const handleScroll = () => {
      const elements = document.querySelectorAll(".aos-init");

      elements.forEach((el) => {
        const rect = el.getBoundingClientRect();
        const triggerPoint = window.innerHeight / 2;

        if (rect.top < triggerPoint) {
          el.style.transitionDuration = "0.2s";
          el.style.transitionTimingFunction = "cubic-bezier(0.4, 0, 1, 1)";
          el.classList.add("aos-animate");
        }
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  if (humantarian?.length === 0 && birthday?.length === 0) return;

  return (
    <div id="The Humanitarian">
      <div>
        <div
          className="h-full md:max-h-[700px] flex justify-between"
          data-aos="fade-right"
          data-aos-delay="300"
        >
          <div className="hidden lg:block" style={{ flex: 1 }}>
            {humantarian?.details?.map((detail, index) => (
              <React.Fragment key={index}>
                {detail?.assets?.map((asset, assetIndex) => (
                  <div
                    className="flex justify-center items-center h-full pl-12"
                    key={assetIndex}
                  >
                    {asset?.images?.map((img, imgIndex) => (
                      <img
                        key={imgIndex}
                        src={convertImagePathToUrl(img.image_url)}
                        alt=""
                        className="w-11/12 object-contain"
                      />
                    ))}
                  </div>
                ))}
              </React.Fragment>
            ))}
          </div>
          <div
            className="flex flex-col items-center py-10 lg:py-0"
            style={{ flex: 1.75 }}
          >
            <div
              className="flex flex-col justify-center mx-20 lg:mx-0 h-full"
              style={{ maxWidth: "650px" }}
            >
              <Typography
                className="mb-2 text-4xl font-bold"
                style={{
                  fontFamily: "Montserrat",
                }}
              >
                {humantarian?.section_heading}
              </Typography>
              {humantarian?.details?.map((detail) => (
                <div key={detail.detail_id}>
                  {detail?.sub_headings?.map((subHeading) => (
                    <div key={subHeading.sub_heading_id}>
                      <Typography
                        className="text-base my-3"
                        style={{
                          fontWeight: 600,
                          fontFamily: "Montserrat",
                        }}
                      >
                        {subHeading.sub_heading}
                      </Typography>
                      <Typography
                        className="mx-auto"
                        style={{
                          fontSize: 14,
                          fontWeight: 500,
                          fontFamily: "Montserrat",
                        }}
                        dangerouslySetInnerHTML={{
                          __html: formatModalDescription(
                            subHeading?.sub_heading_desc
                          ), // Correctly using dangerouslySetInnerHTML here
                        }}
                      />
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
          <div
            className="hidden lg:block"
            style={{ flex: 1, backgroundColor: "#EBEEF3" }}
          >
            <img
              src={WhiteArrowRight}
              alt=""
              className="h-full mr-auto leftArrowStyle"
              style={{ backgroundColor: "#EBEEF3" }}
            />
          </div>
        </div>
      </div>

      {/* <div
        className="portfolioImg4"
        style={{ overflowX: "hidden", overflowY: "hidden" }}
      >
        <div
          className="h-full md:h-[700px] flex justify-between"
          data-aos="fade-left"
          data-aos-delay="300"
        >
          <div className="justify-end hidden lg:flex" style={{ flex: 1 }}>
            <img
              src={HumantarianImg3}
              alt=""
              className="h-full ml-auto leftArrowStyle"
            />
          </div>
          <div
            className="flex flex-col items-center"
            style={{ flex: 2, backgroundColor: "#EBEEF3" }}
          >
            <div
              className="py-[10rem] mx-2 lg:mx-0"
              style={{ maxWidth: "580px" }}
            >
              {birthday?.details?.map((detail) => (
                <div key={detail.detail_id}>
                  {detail?.sub_headings?.map((subHeading) => (
                    <div className="py-8" key={subHeading.sub_heading_id}>
                      <Typography
                        className="text-base font-semibold"
                        style={{
                          fontFamily: "Montserrat",
                        }}
                      >
                        {subHeading.sub_heading}
                      </Typography>

                      <Typography
                        className="mx-auto my-5"
                        style={{
                          fontSize: 14,
                          fontWeight: 500,
                          fontFamily: "Montserrat",
                        }}
                        dangerouslySetInnerHTML={{
                          __html: formatModalDescription(
                            subHeading?.sub_heading_desc
                          ), // Correctly using dangerouslySetInnerHTML here
                        }}
                      />
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
          <div
            className="justify-end hidden pt-14 lg:flex"
            style={{ flex: 1, backgroundColor: "#EBEEF3" }}
          >
            {birthday?.details?.map((detail, index) => (
              <React.Fragment key={index}>
                {detail?.assets?.map((asset, assetIndex) => (
                  <div key={assetIndex} className="">
                    {asset?.images?.map((img, imgIndex) => (
                      <img
                        key={imgIndex}
                        alt=""
                        src={convertImagePathToUrl(img.image_url)}
                        className="w-[434px] h-[641px] object-contain mr-auto leftArrowStyle xl:py-10 2xl:px-8"
                      />
                    ))}
                  </div>
                ))}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div> */}
      <div id="The Author" className="portfolioImg4">
        <AuthorPolygon author={author} />
      </div>
    </div>
  );
};
